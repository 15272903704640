<template>
  <Popup :header="$t('message.login')" classes="md">
    <!-- <svg role="img" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-labelledby="entranceIconTitle">
      <title id="entranceIconTitle">Entrance</title>
      <path d="M11 15l3-3-3-3"></path>
      <path d="M4.5 12H13"></path>
      <path stroke-linecap="round" d="M14 12h-1"></path>
      <path d="M18 4v16H7V4z"></path>
    </svg>&nbsp; -->
    <div class="form p-1">
      <div v-if="state.isErrors" class="errors">
        <label class="error-text" v-for="(err, k) in state.errorSet" :key="k">
          {{ err }}
        </label>
      </div>
      <form
        @submit.prevent="submit"
        :class="state.isErrors ? 'form-has-errors' : ''"
      >
        <div class="grid gap">
          <div class="form-group">
            <input
              type="text"
              v-focus
              class="form-input"
              :placeholder="$t('message.username/email')"
              v-model="form.email"
            />
            <label>{{ $t("message.username/email") }}</label>
          </div>
          <div class="form-group">
            <input
              type="password"
              v-focus
              class="form-input"
              :placeholder="$t('message.password')"
              v-model="form.password"
            />
            <label>{{ $t("message.password") }}</label>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <button class="form-btn" v-if="!form.loading" @click="login">
          {{ $t("message.log_in") }}
        </button>
        <button class="form-btn disabled" disabled v-if="form.loading">
          Logging you in...
        </button>
      </form>
      <div class="clearfix"></div>
      <br />
      <a class="a-link" href="javascript:;">{{ $t("message.need_help?") }}</a>
      <div class="clearfix"></div>
      <br />
      <p class="t-lbl">
        {{ $t("message.dont_have_an_account") }}
        <a @click="openRegisterPopup" class="a-link">{{
          $t("message.register")
        }}</a>
      </p>
    </div>
  </Popup>
</template>

<script>
// import { ref } from '@vuek/reactivity'
import Popup from "@/components/Popup.vue";
import { onMounted, reactive, ref } from "@vue/runtime-core";
import helper from "@/helper.js";
import { useStore } from "vuex";
import router from "@/router";
export default {
  name: "Login",
  components: { Popup },
  setup() {
    const store = useStore();
    const form = ref({
      loading: false,
      email: null,
      password: null,
    });
    const state = reactive({
      isErrors: false,
      errorSet: [],
    });
    const emitter = helper().emitter();
    onMounted(() => {
      emitter.emit("model-update");
    });

    // ...mapActions({
    //   logIn: 'auth/logIn'
    // })

    async function login() {
      form.value.loading = true;
      state.isErrors = false;
      state.errorSet = [];
      await store
        .dispatch("auth/logIn", form.value)
        .then((res) => {
          form.value.loading = false;
          if (res.status === "error") {
            state.isErrors = true;
            state.errorSet.push(res.message);
          }
          router.push({ name: "Dashboard" });
        })
        .catch(() => {
          // console.log("Err", err);
          alert("Login err: Something went wrong!");
        });
    }

    function openRegisterPopup() {
      emitter.emit("open-popup", "register");
    }

    return {
      state,
      login,
      form,
      openRegisterPopup,
    };
  },
};
</script>
