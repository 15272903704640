<template>
  <header class="header">
    <div class="container">
      <div class="bg-circle"></div>
      <div class="menu">
        <a href="/" class="logo">
          <img :src="require('@/assets/images/logo.svg')" alt="Logo" />&nbsp;
        </a>
        <ul class="menu__list">
          <li>
            <a href="javascript:;" ap-click
              >{{ $t("message.products") }}&nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-chevron-down"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#2c3e50"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="6 9 12 15 18 9" />
              </svg>
            </a>
            <ul class="menu__dropdown" ap-dropdown>
              <li>
                <a href="javascript:;">
                  <span class="menu__dropdown-ico">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-file-invoice"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#597e8d"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                      <path
                        d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
                      />
                      <line x1="9" y1="7" x2="10" y2="7" />
                      <line x1="9" y1="13" x2="15" y2="13" />
                      <line x1="13" y1="17" x2="15" y2="17" />
                    </svg>
                  </span>
                  <span class="menu__dropdown-lbl">
                    <label class="menu__dropdown-title">{{
                      $t("message.accounting")
                    }}</label>
                    <br />
                    <span class="menu__dropdown-caption"
                      >Lorem ipsum dolor sit!</span
                    >
                  </span>
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <span class="menu__dropdown-ico">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-report-money"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#597e8d"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"
                      />
                      <rect x="9" y="3" width="6" height="4" rx="2" />
                      <path
                        d="M14 11h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"
                      />
                      <path d="M12 17v1m0 -8v1" />
                    </svg>
                  </span>
                  <span class="menu__dropdown-lbl">
                    <label class="menu__dropdown-title">{{
                      $t("message.finance")
                    }}</label>
                    <br />
                    <span class="menu__dropdown-caption"
                      >Lorem ipsum dolor sit!</span
                    >
                  </span>
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <span class="menu__dropdown-ico">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-currency-real"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#597e8d"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M21 6h-4a3 3 0 0 0 0 6h1a3 3 0 0 1 0 6h-4" />
                      <path d="M4 18v-12h3a3 3 0 1 1 0 6h-3c5.5 0 5 4 6 6" />
                      <path d="M18 6v-2" />
                      <path d="M17 20v-2" />
                    </svg>
                  </span>
                  <span class="menu__dropdown-lbl">
                    <label class="menu__dropdown-title">{{
                      $t("message.ledger")
                    }}</label>
                    <br />
                    <span class="menu__dropdown-caption"
                      >Lorem ipsum dolor sit!</span
                    >
                  </span>
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <span class="menu__dropdown-ico">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-calendar-time"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#597e8d"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"
                      />
                      <circle cx="18" cy="18" r="4" />
                      <path d="M15 3v4" />
                      <path d="M7 3v4" />
                      <path d="M3 11h16" />
                      <path d="M18 16.496v1.504l1 1" />
                    </svg>
                  </span>
                  <span class="menu__dropdown-lbl">
                    <label class="menu__dropdown-title">{{
                      $t("message.financial_year")
                    }}</label>
                    <br />
                    <span class="menu__dropdown-caption"
                      >Lorem ipsum dolor sit!</span
                    >
                  </span>
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <span class="menu__dropdown-ico">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-receipt-tax"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#597e8d"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <line x1="9" y1="14" x2="15" y2="8" />
                      <circle cx="9.5" cy="8.5" r=".5" fill="currentColor" />
                      <circle cx="14.5" cy="13.5" r=".5" fill="currentColor" />
                      <path
                        d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2"
                      />
                    </svg>
                  </span>
                  <span class="menu__dropdown-lbl">
                    <label class="menu__dropdown-title">{{
                      $t("message.taxes")
                    }}</label>
                    <br />
                    <span class="menu__dropdown-caption"
                      >Lorem ipsum dolor sit!</span
                    >
                  </span>
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <span class="menu__dropdown-ico">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-receipt"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#597e8d"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2"
                      />
                    </svg>
                  </span>
                  <span class="menu__dropdown-lbl">
                    <label class="menu__dropdown-title">{{
                      $t("message.invoices")
                    }}</label>
                    <br />
                    <span class="menu__dropdown-caption"
                      >Lorem ipsum dolor sit!</span
                    >
                  </span>
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <span class="menu__dropdown-ico">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-cash"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#597e8d"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <rect x="7" y="9" width="14" height="10" rx="2" />
                      <circle cx="14" cy="14" r="2" />
                      <path
                        d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2"
                      />
                    </svg>
                  </span>
                  <span class="menu__dropdown-lbl">
                    <label class="menu__dropdown-title">{{
                      $t("message.balance")
                    }}</label>
                    <br />
                    <span class="menu__dropdown-caption"
                      >Lorem ipsum dolor sit!</span
                    >
                  </span>
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <span class="menu__dropdown-ico">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-receipt"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#597e8d"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2"
                      />
                    </svg>
                  </span>
                  <span class="menu__dropdown-lbl">
                    <label class="menu__dropdown-title">{{
                      $t("message.gst")
                    }}</label>
                    <br />
                    <span class="menu__dropdown-caption"
                      >Lorem ipsum dolor sit!</span
                    >
                  </span>
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <span class="menu__dropdown-ico">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-chart-area-line"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#597e8d"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <polyline
                        points="4 19 8 13 12 15 16 10 20 14 20 19 4 19"
                      />
                      <polyline points="4 12 7 8 11 10 16 4 20 8" />
                    </svg>
                  </span>
                  <span class="menu__dropdown-lbl">
                    <label class="menu__dropdown-title">{{
                      $t("message.report")
                    }}</label>
                    <br />
                    <span class="menu__dropdown-caption"
                      >Lorem ipsum dolor sit!</span
                    >
                  </span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:;">{{ $t("message.features") }}</a>
          </li>
          <li>
            <a href="javascript:;">{{ $t("message.services") }}</a>
          </li>
          <li>
            <!-- <a class="btn" href="login"> -->
            <button class="form-btn default" @click="togglePopup('login')">
              {{ $t("message.login") }}
            </button>
            <LoginPopup v-if="popupTriggers.popup == 'login'"></LoginPopup>
            <!-- </a> -->
          </li>
          <li>
            &nbsp;
            <button class="form-btn default" @click="togglePopup('register')">
              {{ $t("message.register") }}
            </button>
            <RegisterPopup
              v-if="popupTriggers.popup == 'register'"
            ></RegisterPopup>
          </li>
        </ul>
      </div>
    </div>
  </header>
  <div class="container">
    <div class="hero">
      <div class="hero-left">
        <h1 class="hero-title">
          <!-- {{ $t("message.account_made_easier_for") }}
          <strong>{{ $t("message.ohada") }}</strong> -->
          Compte simplifié pour
          <strong>OHADA</strong>
        </h1>
        <p class="hero-subtitle">
          {{ $t("message.accounting_tax-financialyear_ledger_balance_etc") }}
        </p>
        <br />
        <button class="form-btn lg">{{ $t("message.get_started") }}</button>
        &nbsp;
        <a href="javascript:;" class="form-link">{{
          $t("message.contact_sales")
        }}</a>
      </div>
      <div class="hero-right">
        <img
          :src="require('@/assets/images/undraw_Finance_re_gnv2.svg')"
          alt="Accounting Prowyser"
        />
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
  <br />
</template>

<script>
import LoginPopup from "@/views/authentication/LoginPopup.vue";
import RegisterPopup from "@/views/authentication/RegisterPopup.vue";
import helper from "@/helper.js";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
export default {
  components: { LoginPopup, RegisterPopup },

  setup() {
    const emitter = helper().emitter();
    const popupTriggers = ref({
      popup: null,
    });

    const togglePopup = async (popup) => {
      popupTriggers.value.popup = popup;
    };

    onMounted(() => {
      emitter.on("close-popup", () => {
        popupTriggers.value.popup = null;
      });
      emitter.on("open-popup", (type) => {
        popupTriggers.value.popup = type;
      });
    });

    return {
      popupTriggers,
      togglePopup,
    };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Cantarell:wght@400;700&family=Inter:wght@200;300;400;500;600;700;800;900&family=Padauk:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@400;500;700&display=swap");
:root {
  --tff: "Inter";
  --ff: "Cantarell", sans-serif;
  --menuclr: #124472;
  --pclr: #267baa;
  --dclr: #124472;
  --sclr: #5ebad1;
  --lclr: #fff;
  /* #dfe1df */
  --bclr: #4c4c4c;
  --gclr: #6c6c6c;
  --radius: 5px;
  --transition: all 0.25s;
}
body {
  font-family: var(--pff);
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
@import "@/assets/scss/variables";
@import "@/assets/scss/general";
@import "@/assets/scss/typography";
@import "@/assets/scss/form";
@import "@/assets/scss/grid";
@import "@/assets/scss/popup";

body {
  margin: 0;
  font-family: var(--ff);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  position: relative;
  border-bottom: 1px solid #ccc;
  width: 100%;
  background: #f3f5f7dd;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.logo img {
  height: 3.75rem;
}

.logo label {
  font-family: var(--ff);
  font-weight: 700;
  color: var(--dclr);
  font-size: 1.1rem;
  margin-left: 10px;
  cursor: pointer;
}

.menu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu__list > li {
  display: inline-block;
  padding: 16px 0;
}

.menu__list > li > a {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 7px 12px;
  margin: 0 10px;
  font-size: 1rem;
  color: var(--dclr);
  text-decoration: none;
  border-radius: 5px;
  transition: var(--transition);
}
.menu__list > li > a.btn {
  padding: 0;
}

.menu__dropdown {
  position: absolute;
  width: 100%;
  background: #fff;
  padding: 16px;
  list-style: none;
  margin: 0;
  box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.15);
  border: 1px solid #ccc;
  border-radius: 0 0 10px 10px;
  left: 0;
  top: 100%;
  z-index: 9;
  transition: var(--transition);
  transform-origin: top;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0.5);
}

.menu__dropdown li {
  /* display: inline-block; */
  width: 33.3%;
  float: left;
}

.menu__dropdown li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 16px;
  border-radius: 10px;
  transition: var(--transition);
}

.menu__dropdown li a .menu__dropdown-ico {
  background: #dfe1df;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}
.menu__dropdown li a:hover .menu__dropdown-ico {
  background: #fff;
}
.menu__dropdown li a .menu__dropdown-lbl {
  margin-left: 15px;
}

.menu__dropdown li a .menu__dropdown-title {
  font-family: var(--ff);
  color: var(--bclr);
  font-weight: 600;
}
.menu__dropdown li a .menu__dropdown-caption {
  font-family: var(--ff);
  color: var(--gclr);
}
.menu__dropdown li a:hover {
  background: #267baa33;
}

.menu__list > li:hover > a {
  background: #cccccc66;
}

.menu__list li:hover .menu__dropdown {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}

.hero {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 2rem 0;
}

.hero-title {
  font-family: var(--tff);
  font-weight: 800;
  color: var(--dclr);
  font-size: 4rem;
  line-height: 1.1;
}

.hero-subtitle {
  font-family: var(--tff);
  font-weight: 500;
  color: var(--gclr);
  font-size: 1.1rem;
}

.hero-right img {
  width: 100%;
}

.form-btn.lg {
  padding: 0.5rem 2rem;
  height: inherit;
  font-weight: 500;
  font-family: var(--tff);
}

.form-link {
  font-family: var(--tff);
  text-decoration: none;
  font-weight: 500;
  color: var(--dclr);
}

.bg-circle {
  position: absolute;
  background-color: var(--pclr);
  background-image: linear-gradient(100deg, var(--pclr), #4259ff);
  top: -400px;
  left: -350px;
  border-radius: 100%;
  height: 800px;
  width: 800px;
  opacity: 0.15;
  z-index: -1;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .bg-circle {
    left: -450px;
  }
}
@media (max-width: 768px) {
  .bg-circle {
    left: -550px;
  }
}
</style>
