<template>
  <Popup :header="$t('message.register')" classes="ls">
    <!-- <svg role="img" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-labelledby="entranceIconTitle">
      <title id="entranceIconTitle">Entrance</title>
      <path d="M11 15l3-3-3-3"></path>
      <path d="M4.5 12H13"></path>
      <path stroke-linecap="round" d="M14 12h-1"></path>
      <path d="M18 4v16H7V4z"></path>
    </svg>&nbsp; -->
    <form
      v-if="!state.isRegistered"
      @submit.prevent="register"
      :class="['form', 'p-1', state.isErrors ? 'form-has-errors' : '']"
    >
      <div v-if="state.isErrors" class="errors">
        <label class="error-text" v-for="(err, k) in state.errorSet" :key="k">
          {{ err }}
        </label>
      </div>
      <div class="grid gap">
        <div class="form-group">
          <input
            type="text"
            v-focus
            class="form-input"
            v-model="form.name"
            :placeholder="$t('message.name')"
          />
          <label>{{ $t("message.name") }}</label>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <div class="grid grid-2 gap">
        <div class="form-group">
          <input
            type="text"
            v-focus
            class="form-input"
            v-model="form.company_name"
            :placeholder="$t('message.company_name')"
          />
          <label>{{ $t("message.company_name") }}</label>
        </div>
        <div class="form-group">
          <input
            type="text"
            v-focus
            class="form-input"
            v-model="form.salesmane_code"
            :placeholder="$t('message.salesmane_code')"
          />
          <label>{{ $t("message.salesmane_code") }}</label>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <div class="grid gap">
        <div class="form-group">
          <input
            type="email"
            v-focus
            class="form-input"
            v-model="form.email"
            :placeholder="$t('message.email_address')"
          />
          <label>{{ $t("message.email_address") }}</label>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <div class="grid grid-2 gap">
        <div class="form-group">
          <input
            type="password"
            v-focus
            v-model="form.password"
            class="form-input"
            :placeholder="$t('message.password')"
          />
          <label>{{ $t("message.password") }}</label>
        </div>
        <div class="form-group">
          <input
            type="password"
            v-focus
            class="form-input"
            v-model="form.password_confirmation"
            :placeholder="$t('message.confirm_password')"
          />
          <label>{{ $t("message.confirm_password") }}</label>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <button class="form-btn" v-if="!form.loading">
        {{ $t("message.register") }}
      </button>
      <button class="form-btn disabled" disabled v-if="form.loading">
        Loading...
      </button>
      <div class="clearfix"></div>
      <br />
      <p class="t-lbl">
        {{ $t("message.already_have_account") }}
        <a @click="openLoginPopup" class="a-link">{{ $t("message.log_in") }}</a>
      </p>
    </form>
    <div v-if="state.isRegistered">
      <h2>{{ $t("message.thank_you_!") }}</h2>
      <h3 class="t-lbl">
        {{ $t("message.You’re_registered_successfully_with_Prohada")
        }}<strong>Prohada.</strong>
      </h3>
      <br />
      {{ $t("message.log_in") }}
      <a @click="openLoginPopup" class="a-link" href="#">here</a>
      <br />
    </div>
  </Popup>
</template>

<script>
// import { ref } from '@vue/reactivity'
import Popup from "@/components/Popup.vue";
import { onMounted, reactive, ref } from "@vue/runtime-core";
import helper from "@/helper.js";
import { useStore } from "vuex";
export default {
  components: { Popup },
  setup() {
    const state = reactive({
      isErrors: false,
      errorSet: [],
      isRegistered: false,
    });
    const store = useStore();
    const form = ref({
      loading: false,
      name: null,
      email: null,
      password: null,
      company_name: null,
      salesmane_code: null,
      password_confirmation: null,
    });
    const emitter = helper().emitter();
    onMounted(() => {
      emitter.emit("model-update");
    });

    async function register() {
      form.value.loading = true;
      state.errorSet = [];
      state.isErrors = false;
      await store
        .dispatch("auth/register", form.value)
        .then((res) => {
          form.value.loading = false;
          if (res.status === "error") {
            state.isErrors = true;
            state.errorSet.push(res.message);
          }
          if (res.status === "success") {
            state.isRegistered = true;
          }
        })
        .catch(() => {
          alert("Login err: Something went wrong!");
        });
    }

    function openLoginPopup() {
      emitter.emit("open-popup", "login");
    }

    return {
      register,
      form,
      state,
      openLoginPopup,
    };
  },
};
</script>
